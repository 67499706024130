import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "@emotion/styled"
import MenuDrawer from "./menu-drawer"
import ImageLogoElWhite from "./image-logo-el-white"
import ImageLogoLucilles from "./image-logo-lucilles"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faBars, faWindowClose, faCaretRight } from "@fortawesome/free-solid-svg-icons"

const executeScroll = (ref) => {
  ref.current.scrollIntoView({behavior: "smooth"})   
}

const MenuBar = styled.header`
  position: absolute;
  display: flex;
  marginBottom: 1.45rem;
  width: 100%;
  z-index: 998;
  font-size: 16px;
  text-shadow: 4px 4px 4px #00000069;
  @media (min-width: 1024px) {
    display: flex;
  }
`
const MenuLeft = styled.div`
  z-index: 1;
  margin: 10px 0;
  @media (min-width: 1024px) {
    flex: 1;
  }
  @media (min-width: 1200px) {
    padding: 1rem 1.0rem;
    padding-left: 0;
  }
  @media (min-width: 1400px) {
    padding: 1.45rem 1.0rem;
    padding-left: 0;
  }
`
const LogoContainer = styled.div`
  .gatsby-image-wrapper {
    width: 80px;
  }
  @media (min-width: 1400px) {
    .gatsby-image-wrapper {
      width: 120px;
    }
  }
`
const MenuCenter = styled.div`
  z-index: 1;
  margin: 0 auto;
  max-width: 660px;
  min-width: 660px;
  padding: 1.45rem 1.0875rem;
  a {
    margin-right: 10px;
  }
  a:hover {
    border-bottom: 2px solid #06aad4;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex: 1;
    max-width: 660px;
    min-width: 660px;
  }
  @media (min-width: 1200px) {
    flex: 1;
    max-width: 860px;
    min-width: 860px;
  }
  @media (min-width: 1600px) {
    flex: 1;
    max-width: 960px;
    min-width: 960px;
  }
`
const LeftFrame = styled.div`
  flex: 2;
  a {
    font-size: 16px;
    padding: 4px 8px;
  }
  @media only screen and (min-width: 1024px) {
    a {
    }
  }
`
const RightFrame = styled.div`
  flex: 1;
  text-align: right;
`
const MenuRight = styled.div`
  z-index: 1;
  padding: 1.45rem 1.0875rem;
  @media (min-width: 1024px) {
    flex: 1;
  }
`
const ButtonDrawer = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  border: 0;
  background-color: transparent;
  &:hover {
    cursor:pointer;
  }
  .gatsby-image-wrapper {
    margin-left: 10px;
  }
  @media (min-width: 1200px) {
    .gatsby-image-wrapper {
      margin-left: 20px;
    }
  }
`
const Caret = styled.div`
  color: #00abd4;
`
const Close = styled.div`
  color: #fff;
  padding-left: 70px;
  font-size: 22px;
  @media(min-width: 1200px) {
    padding-left: 40px;
  }
`
const LanguageSelector = styled.div`
  flex: 1;
  a {
    margin-right: 10px;
    border-bottom: 2px solid #fff;
  }
  a:hover {
    border-bottom: 2px solid #06aad4;
  }
  @media (min-width: 1024px) {
    text-align: right;
  }
`
const SocialIcon = styled.a`
  font-size: 16px;
  color: #00ABD4;
  padding: 6px;
  @media (min-width: 768px) {
    padding: 0 4px;
  }
`
const PageLinkButton = styled.button`
  display: none;
  font-size: 18px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-family: 'Oswald';
  color: #fff;
  padding: 0 10px 4px 10px;
  margin-right: 10px;
  text-shadow: #00000069 4px 4px 4px;
  &:hover {
    border-bottom: 2px solid #06abd4;
  }
`

const Desktop = ({
    lang, 
    page_links, 
    social_links, 
    splash_url, 
    openState,
    setOpenState,
    image
  }) => {

  const path = lang.includes('en') ? '/' : '/en/'
  const pathLabel = lang.includes('en') ? 'FR' : 'EN'

  return (
    <MenuBar>
      <MenuDrawer 
        lang={lang}
        openState={openState} 
        setOpenState={setOpenState} 
        closeButton
      />
      <MenuLeft>
        <LogoContainer>
          <ButtonDrawer 
            onClick={() => setOpenState(!openState) }>
            <Caret>
              <FontAwesomeIcon icon={faCaretRight} />
            </Caret>
            { image() }
          </ButtonDrawer>
        </LogoContainer>
      </MenuLeft>
      <MenuCenter>
        <LeftFrame>
          { page_links.map((e) => (
              <PageLinkButton onClick={()=>executeScroll(e.ref)}>
                { e.name }
              </PageLinkButton>
          ))}
        </LeftFrame>
        <RightFrame>
          { social_links.map((e) => (
              <SocialIcon href={e.href}>
                <FontAwesomeIcon icon={e.icon}/>
              </SocialIcon>
          ))}
        </RightFrame>
      </MenuCenter>
      <MenuRight>
        <LanguageSelector>
          <Link to={path}>{pathLabel}</Link>
        </LanguageSelector>
      </MenuRight>
    </MenuBar>
  )
}

export default Desktop
