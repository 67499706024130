import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

import imgBG from "../images/bg-texture.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Container = styled.div`
	border-top: 10px solid #00abd4;
  max-width: 714px;
  margin: auto;
  padding: 1.5rem 0;
  h3 {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 30px 0; 
  }
  li {
    padding: 0 0 4px 0;
    margin: 0; 
    font-size: 14px;
  }
  li a {
    padding: 0;
    font-family: 'Open Sans';
    border-bottom: 0;
    color: #fff;
    text-decoration: none;
  }
  li a:hover {
    color: #00ABD4;
  }
  @media (min-width: 1024px) {
    max-width: 1000px;
    padding: 4rem 0 2rem 0;
    position: relative;
    margin: 0 auto;
    h3 {
      font-size: 16px;
    }
    li {
      font-size: 16px;
    }
  }
`
const Wrapper = styled.div`
	max-width: 740px;
	margin: auto;
`
const Box = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`
const Column = styled.div`
  @media (min-width: 768px) {
    flex: 1;
  }
`
const Bar = styled.div`
  border-top: 1px solid #333;
  @media (min-width: 1024px) {
    border-top: 1px solid #333;
    position: relative;
  }
`
const Left = styled.div`
  padding-bottom: 4px;
  @media (min-width: 1024px) {
    position: absolute;
    top: 20px;
    right: 0;
  }
`
const Right = styled.div`
  padding-bottom: 4px;
  @media (min-width: 1024px) {
    position: absolute;
    top: 20px;
    left: 0;
  }
`
const SocialIcon = styled.a`
  padding-right: 10px;
  color: #00ABD4;
  &:hover {
    color: #fff;
  }
`
const Button = styled.a`
  display: inline-block;
  background-color: #fff;
  margin: 40px 0 30px 0;
  padding: 4px 14px;
  color: #060809;
  font-family: 'Oswald';
  font-size: 16px;
  border: 2px solid #414141;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #00ABD4;
    border: 2px solid #00ABD4;
  }
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
`
const Copyright = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
  color: #e2e2e2;
  font-size: 10px;
  @media (min-width: 1024px) {
    padding-top: 40px;
    text-align: center;
  }
`
const Foot = styled.footer`
  margin: 0 10px 20px 10px;
  background-color: transparent;
  border-top: 10px solid #414141;
  color: #fff;
  padding: 4px 20px 30px 20px;
  @media (min-width: 768px) {
    max-width: 700px;
    margin: 20px auto 200px auto;
    padding: 4px 30px 30px 30px;
  }
  @media (min-width: 1024px) {
    max-width: 900px;
    margin: 40px auto 200px auto;
  }
  @media (min-width: 1024px) {
    max-width: 1000px;
  }
`
const LanguageSelector = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 80px;
  }
  a {
    margin-right: 10px;
    border-bottom: 2px solid #fff;
  }
  a:hover {
    border-bottom: 2px solid #06aad4;
  }
`
const Heading = styled.h3`
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`

const UnorderedList = ({items}) => {
  let elements = []
  items.forEach((e) => {
    if(e.local) {
      elements.push(<li key={e.name}><Link to={e.url}>{ e.name }</Link></li>)
    } else {
      elements.push(<li key={e.name}><a href={e.url}>{ e.name }</a></li>)
    }
  })
  return <ul>{elements}</ul>
}
const Footer = (props) => {

	const { lang,
          call_to_action_label,
          call_to_action_link,
          global_title,
          service_title,
					global_links,
					service_links } = props

  const pathLink = lang.includes('en') ? '/en/' : '/'
  const homeTitle = lang.includes('en') ? 'HOME' : 'ACCUEIL'

  return (
    <Foot>
      <Container>
				<Wrapper>
					<Box>
						<Column>
              <Heading>{RichText.asText(service_title)}</Heading>
							<UnorderedList items={service_links} />
						</Column>
						<Column>
              <Heading>{RichText.asText(global_title)}</Heading>
							<UnorderedList items={global_links} />
						</Column>
					</Box>

					<Bar>
						<Left>
							<Button href={`//www.lucillesoyster.com${pathLink}`}>{ homeTitle }</Button>
						</Left>

						<Right>
							<SocialIcon href="https://www.facebook.com/pages/Lucilles-Oyster-Dive/200515528427">
								<FontAwesomeIcon icon={faFacebook}/>
							</SocialIcon>
							<SocialIcon href="https://www.instagram.com/lucillesfoodtruck/">
								<FontAwesomeIcon icon={faInstagram}/>
							</SocialIcon>
						</Right>
					</Bar>

					<div>
						<LanguageSelector>
							<Link to="/en/">EN</Link>
							<Link to="/">FR</Link>
						</LanguageSelector>
					</div>
				</Wrapper>
      </Container>
      <Copyright>
        © {new Date().getFullYear()} Lucille's Oyster Dive
      </Copyright>
    </Foot>
  )
}

export default Footer
