export const PRISMIC_EXTERNAL_LINK = "PRISMIC__ExternalLink"

export const PRISMIC_GLOBAL_LINKS_INDEX = 0
export const PRISMIC_SERVICE_LINKS_INDEX = 1
export const PRISMIC_LOCAL_LINKS_INDEX = 0

export const PRISMIC_TYPENAME_HOMEPAGE_MONKLAND = 'allHomepage_monklands'
export const PRISMIC_TYPENAME_HOMEPAGE_ATWATER = 'allHomepage_atwaters'
export const PRISMIC_TYPENAME_HOMEPAGE_FOODTRUCK = 'allHomepage_foodtrucks'

