// Payload Transformation Interface
// simple goal, create functions to shape the data 
// the way your components want it

import { PRISMIC_EXTERNAL_LINK } from "../utils/prismic"

export const getString = (UID, lang, strings) => {
  let string = ''
	if(strings){
		strings.forEach((e) => {
      if(e.node._meta.lang === lang && 
                       UID === e.node._meta.uid) {
        string = e.node.text
      }
		})
	}
  return string
}

export const getItems = (UID, lang, strings) => {
  let string = ''
	if(strings){
		strings.forEach((e) => {
      if(e.node._meta.lang === lang && 
                       UID === e.node._meta.uid) {
        string = e.node.text
      }
		})
	}
  return string
}

export const getLinks = (lang, edges) => {
  let links = []
	if(edges) {
		edges.forEach((e) => {
      if(e.node._meta.lang === lang && e.node.body) {
        links= e.node.body[0].fields.map(f => {
          const link = {
            name: f.display_text,
            url: f.url.url,
            local: f.link_location === "External" 
              ? false : true
          }
          return link
        })
      }
		})
	}
  return links
}

// transforms a prismic "links" slice to "list" components
// expected data format
export const transformLinksBK = (edge) => {
  let links = []
	if(edge.body) {
    links= edge.body[0].fields.map(f => {
      const link = {
        name: f.display_text,
        url: f.url.url,
        local: f.link_location === "External" 
          ? false : true
      }
      return link
    })
  }
  return links
}

// transforms a prismic "links" slice to "list" components
// expected data format
export const transformLinks = (edge, index) => {
  let links = []
	if(edge.body) {
    links= edge.body[index].fields.map(f => {
      const link = {
        name: f.label,
        url: f.link.url,
        local: f.link.__typename === PRISMIC_EXTERNAL_LINK
          ? false : true
      }
      return link
    })
  }
  return links
}

export const getTitle = (edge, index) => {
  let title = []
	if(edge.body) {
    title = edge.body[index].primary.title
  }
  return title
}

// transforms a prismic "Homepage" type to "page" template
// expected data format
export const transformHomepage = (edge) => {
  let fields = {}
	if(edge) {
    const node = edge[0].node
    fields = {
      hero_headline: node,
    }
  }
  return fields
}

export const selectLanguage = (lang, edges) => {
  let edge = {}
	if(edges) {
		edges.forEach((e) => {
      if(e.node._meta.lang === lang) {
        edge = e.node
      }
		})
	}
  return edge
}

export const selectHomepage = (data, type) => {
  return data.prismic[type].edges[0].node.body[0].primary
} 

export const selectImages = (data, type) => {
  return data.prismic[type].edges[0].node.body[1].fields
} 

export const selectFields = (edge, index) => {
  return { 
    items : edge.node.body[index].fields,
    name: edge.node.body[index].primary.name 
  }
} 

